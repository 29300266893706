<template>
	<div id="login">
		<div class="background-banner"  v-bind:style="{ backgroundImage: 'url('+banner_background+')' }"></div>
		<div class="container lateral-wrapper">
			<div class="offset-md-3 col-md-6 offset-sm-2 col-sm-8 col-12 lateral" elevation="2">
				<!-- <div class="logo-empresa"> -->
					<LogoEmpresas :logo="LogoEmpresa"/>
				<!-- </div> -->
				<!-- <div class="entrar-com">
					<div class="row">
						<div class="text-entrar">
							<p>Entrar com</p>
						</div>
						<div class="col-sm-6 col-12">
							<v-btn class="btn-entrar-com facebook">
								<img class="img-responsive" alt="Logo Facebook" :src="logo_facebook"> Facebook	
							</v-btn>
						</div>
						<div class="col-sm-6 col-12">
							<v-btn class="btn-entrar-com google">
								<img class="img-responsive" alt="Logo Google" :src="logo_google"> Google	
							</v-btn>
						</div>
						<div class="text-ou">
							<p>ou entre com e-mail</p>
						</div>
					</div>
				</div> -->
				<v-form ref="form_usuario">
					<div class="row row-inputs">
						<div class="col-12">
							<label class="primary-text-field-label">Digite seu e-mail*</label>
							<v-text-field
								class="primary-text-field"
								:rules="[v => !!v || 'Campo obrigatório']" 
								v-model="inputs.usuario"
								label="E-mail"
								solo
								hide-details
								@keyup.enter="handleSubmit"
							/>
						</div>
						<div class="col-12">
							<label class="primary-text-field-label">Senha*</label>
							<v-text-field
								class="primary-text-field"
								type="password" 
								:rules="[v => !!v || 'Campo obrigatório']" 
								v-model="inputs.senha"
								label="Senha"
								solo
								hide-details
								@keyup.enter="handleSubmit"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6 col-12 lembrar-usuario">
							<v-checkbox 
								v-model="inputs.lembrar_usuario"
								label="Relembre-me"
								hide-details
							/>
						</div>
						<!-- <div class="col-sm-6 col-12 recuperar-senha">
							<router-link to="/admin/recuperar-senha">Esqueceu a senha?</router-link>
						</div> -->
						<div class="col-12 div-wrapper-btn">
							<v-btn class="btn-entrar" @click="handleSubmit" elevation="2" large color="primary" :loading="loading">
								Entrar
							</v-btn>
						</div>
					</div>
					<div class="row">
						<div class="col-12 wrapper-novo-usuario">
							<span>
								Novo por aqui?
							</span>
							<v-btn @click="modalCreateUsuario = !modalCreateUsuario; buscaEstados()" class="btn-cadastrese">
								Cadastre-se
							</v-btn>
						</div>
					</div>
				</v-form>
			</div>
			<div class="wrapper-logoFinerFoods">
				<LogoFinerFoods/>
			</div>
		</div>
		<Loader v-if="loading"/>
		<v-dialog v-model="modalCreateUsuario" persistent max-width="800px" transition="dialog-bottom-transition">
			<v-card class="modal-CriaUsuario">
				<div class="col-12 d-flex justify-space-between align-center">
					<v-card-title class="pl-3">
						Cadastro de Usuário
						<label> * Campos obrigatórios </label>
					</v-card-title>
					<v-btn class="mx-3 p-10" fab dark small color="#FE8204" @click="modalCreateUsuario = !modalCreateUsuario">
						<v-icon dark>
							mdi-close
						</v-icon>
					</v-btn>
				</div>
				<v-card-text>
					<v-form ref="form_novo_usuario" class="row">
						<div class="col-12 px-2 pt-2">
							<v-divider></v-divider>
						</div>
						<div class="col-12">
							<label class="primary-text-field-label">Nome*</label>
							<v-text-field
								class="primary-text-field"
								:rules="[v => !!v || 'Campo obrigatório']" 
								v-model="novoCliente.nome"
								label="Nome*"
								solo
								hide-details
							/>
						</div>
						<div class="col-md-6 col-12">
							<label class="primary-text-field-label">Email*</label>
							<v-text-field
								class="primary-text-field"
								:rules="[v => !!v || 'Campo obrigatório']" 
								v-model="novoCliente.email"
								label="Email*"
								solo
								hide-details
							/>
						</div>
						<div class="col-md-6 col-12">
							<label class="primary-text-field-label">Senha*</label>
							<v-text-field
								class="primary-text-field"
								type="password"
								:rules="[v => !!v || 'Campo obrigatório']" 
								v-model="novoCliente.senha"
								label="Senha*"
								solo
								hide-details
							/>
						</div>
						<div class="col-md-6 col-12">
							<label class="primary-text-field-label">Telefone*</label>
							<v-text-field
								class="primary-text-field"
								:rules="[v => !!v || 'Campo obrigatório']" 
								v-model="novoCliente.telefone"
								label="Telefone*"
								solo
								hide-details
								v-mask="'(##) #####-####'"
							/>
						</div>
						<div class="col-md-6 col-12">
							<label class="primary-text-field-label">CPF*</label>
							<v-text-field
								class="primary-text-field"
								:rules="[v => !!v || 'Campo obrigatório']" 
								v-model="novoCliente.cpf"
								label="CPF*"
								solo
								hide-details
								v-mask="'###.###.###-##'"
							/>
						</div>
						<div class="col-12 px-7 pt-7">
							<v-divider></v-divider>
						</div>
						<div class="col-md-4 col-12">
							<label class="primary-text-field-label">CEP*</label>
							<v-text-field
								class="primary-text-field"
								:rules="[v => !!v || 'Campo obrigatório']" 
								v-model="novoCliente.enderecos[0].cep"
								label="CEP*"
								solo
								hide-details
								v-mask="'#####-###'"
								@change="buscaCep()"
							/>
						</div>
						<div class="col-md-8 col-12">
							<label class="primary-text-field-label">Endereço*</label>
							<v-text-field
								class="primary-text-field"
								:rules="[v => !!v || 'Campo obrigatório']" 
								v-model="novoCliente.enderecos[0].logradouro"
								label="Endereço*"
								solo
								hide-details
							/>
						</div>
						<div class="col-md-6 col-12">
							<label class="primary-text-field-label">Número*</label>
							<v-text-field
								class="primary-text-field"
								:rules="[v => !!v || 'Campo obrigatório']" 
								v-model="novoCliente.enderecos[0].numero"
								label="Número*"
								type="number"
								solo
								hide-details
							/>
						</div>
						<div class="col-md-6 col-12">
							<label class="primary-text-field-label">UF*</label>
							<v-select
								class="primary-select"
								:rules="[v => !!v || 'Campo obrigatório']" 
								:items="estados"
								item-text="nome" 
								item-value="codigo"
								v-model="novoCliente.enderecos[0].uf_id" 
								label="UF*"
								solo
								hide-details
								@change="buscaCidades()"
							/>
						</div>
						<div class="col-md-6 col-12">
							<label class="primary-text-field-label">Cidade*</label>
							<v-select
								class="primary-select"
								:rules="[v => !!v || 'Campo obrigatório']" 
								:items="cidades"
								item-text="nome" 
								item-value="codigo"
								v-model="novoCliente.enderecos[0].cidade_id" 
								label="Cidade*"
								solo
								hide-details
							/>
						</div>
						<div class="col-md-6 col-12">
							<label class="primary-text-field-label">Bairro*</label>
							<v-text-field
								class="primary-text-field"
								:rules="[v => !!v || 'Campo obrigatório']" 
								v-model="novoCliente.enderecos[0].bairro"
								label="Bairro*"
								solo
								hide-details
							/>
						</div>
						<div class="col-md-6 col-12">
							<label class="primary-text-field-label">Complemento</label>
							<v-text-field
								class="primary-text-field"
								v-model="novoCliente.enderecos[0].complemento"
								label="Complemento"
								solo
								hide-details
							/>
						</div>
						<div class="col-md-6 col-12">
							<label class="primary-text-field-label">Ponto de Referência</label>
							<v-text-field
								class="primary-text-field"
								v-model="novoCliente.enderecos[0].ponto_referencia"
								label="Ponto de Referência"
								solo
								hide-details
							/>
						</div>
						<div class="col-sm-6">
							<div class="row">
								<label style="width: 80%;" class="primary-text-field-label">Deseja receber notificações por WhatsApp?</label>
								<v-switch
									v-model="novoCliente.receber_notificacao"
									hide-details
								/>
							</div>
						</div>
						<div class="col-sm-6">
							<v-text-field
								:disabled="!novoCliente.receber_notificacao"
								v-model="novoCliente.telefone_notificacao"
								placeholder="Telefone"
								v-mask="'(##)#####-####'"
								hide-details
							/>
						</div>
						<div class="col-12 px-7 pt-7">
							<v-divider></v-divider>
						</div>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<div class="wrapper-btns">
						<button class="secondary-button" @click="modalCreateUsuario = !modalCreateUsuario">
							Cancelar
						</button>
						<button class="primary-button" @click="criarNovoUsuario()">
							Salvar
						</button>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import Vue from "vue";
	// import LogoFinerFoods from "@/components/LogoFinerFoods.vue"
	// import LateralLogin from "@/components/LateralLogin.vue"
	import LogoEmpresas from '@/components/LogoEmpresas.vue'
	import store from '@/store'
	import Loader from '@/components/Loader.vue'
	import BG from '@/assets/images/BG.png'
	import LogoOficialFinerFoods from '@/assets/images/LogoOficialFinerFoods.png'
	import LogoFacebook from '@/assets/images/logo-facebook.png'
	import LogoGoogle from '@/assets/images/logo-google.png'
	import VueMask from 'v-mask';
	import LogoFinerFoods from '@/components/LogoFinerFoods.vue'
	
	Vue.use(VueMask);
	export default {
		name: 'LoginCliente',
		components: {
			Loader,
			LogoEmpresas,
			LogoFinerFoods,
			// LateralLogin
		},
		data() {
			return {
				banner_background: BG,
				logo_facebook: LogoFacebook,
				logo_google: LogoGoogle,
				loading: false,
				LogoEmpresa: '',
				inputs: {
					usuario: localStorage.getItem('usuario') || '',
					senha: localStorage.getItem('senha') || '',
					lembrar_usuario: localStorage.getItem('lembrar_usuario') || '',
					cliente: true,
				},
				novoCliente: {
					nome: '',
					cpf: '',
					email: '',
					senha: '',
					telefone: '',
					receber_notificacao: '',
					telefone_notificacao: '',
					enderecos: [{
						cep: '',
						logradouro: '',
						numero: '',
						uf_id: '',
						cidade_id: '',
						bairro: '',
						complemento: '',
						ponto_referencia: ''
					}],
				},
				validacao: {
					usuario: false,
					senha: false,
				},
				modalCreateUsuario: false,
				estados: [],
				cidades: [],
				cidade_id_cep: ''
			}
		},
		methods: {
			async handleSubmit(){
				if (this.$refs.form_usuario.validate()){
					
					this.loading = await true

					let dados = {
						usuario: this.inputs.usuario,
						senha: this.inputs.senha,
						form: 'formCliente'
					}
					
					var resp = await store.dispatch('login', dados)
					
					if(resp.data.sucesso){
						this.$router.push('/' + this.$route.params.url_amigavel)
					}else{
						this.$swal({
                            title: 'Atenção',
                            icon:'warning',
                            text: 'Login inválido',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true,
                            confirmButtonText:'Ok'
                        })
					}
					this.loading = await false
				}
			},
			async criarNovoUsuario(){
				this.loading = await true

				if(	this.novoCliente.nome == '' || 
					this.novoCliente.email == '' || 
					this.novoCliente.senha == '' || 
					this.novoCliente.telefone == '' || 
					this.novoCliente.cpf == '' || 
					this.novoCliente.enderecos[0].cep == '' || 
					this.novoCliente.enderecos[0].logradouro == '' || 
					this.novoCliente.enderecos[0].numero == '' || 
					this.novoCliente.enderecos[0].uf_id == '' || 
					this.novoCliente.enderecos[0].cidade_id == '' || 
					this.novoCliente.enderecos[0].bairro == ''
				){
					this.$swal({
						title: 'Atenção',
						icon:'error',
						text: 'Por favor, preencha todos os campos obrigatórios!',
						showCancelButton: false,
						showCloseButton: false,
						showConfirmButton: true,
						confirmButtonText:'Ok'
					})
					this.loading = await false
				}else{
					var resp = await store.dispatch('salvaCliente', this.novoCliente)
				}
				
				if (resp.sucesso) {
					this.$swal({
						title: 'Atenção',
						icon:'success',
						text: 'Usuário cadastrado com sucesso!',
						showCancelButton: false,
						showCloseButton: false,
						showConfirmButton: true,
						confirmButtonText:'Ok'
					})
					this.modalCreateUsuario = !this.modalCreateUsuario
					this.novoCliente.nome= '',
					this.novoCliente.cpf= '',
					this.novoCliente.email= '',
					this.novoCliente.senha= '',
					this.novoCliente.telefone= '',
					this.novoCliente.receber_notificacao= '',
					this.novoCliente.telefone_notificacao= '',
					this.novoCliente.enderecos= [{}],
					this.loading = await false
				}else{
					this.$swal({
						title: 'Atenção',
						icon:'error',
						text: resp.error[0].message,
						showCancelButton: false,
						showCloseButton: false,
						showConfirmButton: true,
						confirmButtonText:'Ok'
					})
					this.loading = await false
				}
			},
			async buscaEstados(){
				var resp = await store.dispatch('getEstado');
				this.estados = resp
			},
			async buscaCidades(){
				if(this.novoCliente.enderecos[0].uf_id != null){
					var resp = await store.dispatch('getCidade', this.novoCliente.enderecos[0].uf_id)
					this.cidades = resp
					this.novoCliente.enderecos[0].cidade_id = this.cidade_id_cep
				}
			},
			async buscaCep(){
				this.cidade_id_cep = ''

				if(this.novoCliente.enderecos[0].cep != null){
					var resp = await store.dispatch('getCep', this.novoCliente.enderecos[0].cep)

					if(resp.sucesso){
						this.novoCliente.enderecos[0].uf_id = resp.dados.estado
						this.novoCliente.enderecos[0].logradouro = resp.dados.logradouro
						this.novoCliente.enderecos[0].bairro = resp.dados.bairro
						this.novoCliente.enderecos[0].numero = ''
						this.cidade_id_cep = resp.dados.cidade

						this.buscaCidades()
					}else{
						this.novoCliente.enderecos[0].uf_id = ''
						this.novoCliente.enderecos[0].logradouro = ''
						this.novoCliente.enderecos[0].bairro = ''
						this.$swal({
							title: 'Atenção',
							icon:'error',
							text: resp.dados.message,
							showCancelButton: false,
							showCloseButton: false,
							showConfirmButton: true,
							confirmButtonText:'Ok'
						})
					}
				}
			}
		},
		async mounted(){
            this.LogoEmpresa = store.getters.empresa_cardapio.imagem_logo || LogoOficialFinerFoods
		}
	}
</script>

<style lang="scss">
	#login{
		flex: 1;
		display: flex;
		position: relative;
		align-content: center;
		justify-content: center;
		padding: 75px 0;
		.background-banner{
			width: 100%;
    		height: 50%;
			top: 0;
			background-size: cover;
			position: absolute;
		}
		.lateral-wrapper{
			.lateral{
				position: relative;
				background-color: #fff;
				border: 1px solid #EDEDED;
				box-sizing: border-box;
				box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
				border-radius: 26px;
				padding: 0;
				.logo-empresa{
					transform: translateY(-50px);
					width: 150px;
					height: 150px;
					margin: auto;
					position: relative;
					margin-bottom: -50px;
				}
				.entrar-com{
					padding: 0 45px;
					.text-entrar{
						display: flex;
						padding: 0 12px;
						justify-content: center;
						align-items: center;
						width: 100%;
						p{
							font-family: Poppins;
							font-style: normal;
							font-weight: 300;
							font-size: 16px;
							line-height: 24px;
							color: #4F4F4F;
							margin-bottom: 0;
						}
					}
					.btn-entrar-com{
						padding: 10px;
						width: 100%;
						justify-content: center;
						height: auto;
						text-transform: initial;
						font-family: Poppins;
						font-style: normal;
						font-weight: 300;
						font-size: 20px;
						line-height: 30px;
						background: #FFFFFF;
						border: 1px solid #EDEDED;
						box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
						border-radius: 6px;
						&.facebook{
							color: #395185;
						}
						&.google{
							color: #1976D2;
						}
						img{
							margin: 0 15px 0 0;
							width: 35px;
						}
					}
					.text-ou{
						display: flex;
						padding: 0 12px;
						justify-content: center;
						align-items: center;
						width: 100%;
						p{
							font-family: Poppins;
							font-style: normal;
							font-weight: 300;
							font-size: 16px;
							line-height: 24px;
							color: #C4C4C4;
							margin: 0 15px;
							position: relative;
						}
						&::before,
						&::after{
							content: '';
							flex: 1;
							border-top: 1px solid #E5E5E5;
						}
					}
					@media (max-width: 767px) {
						padding: 12px;	
					}
				}
				form{
					padding: 0 45px 20px;
					h2{					
						font-style: normal;
						font-weight: 500;
						font-size: 22px;
						line-height: 36px;
						color: $primarycolor;
					}
					.row-inputs{
						padding-bottom: 15px;
					}
					.v-input__slot{
						border-radius: 100px!important;
						padding-left: 15px;
					}
					.lembrar-usuario{
						display: flex;
						align-items: center;
						label{
							display: flex;
							align-items: center;
							font-family: Poppins;
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 24px;
							color: #747474;
							p{
								padding: 0px;
								font-style: normal;
								font-weight: normal;
								font-size: 14px;
								line-height: 17px;
								color: #373737;
							}
							@media (max-width: 620px){
								font-size: 14px;
							}
							input{
								width: auto;
								margin-right: 10px;
							}
						}
						.v-input__slot{
							border-radius: inherit!important;
							padding: inherit!important;
						}
						.v-input--selection-controls{
							margin: 0;
							padding: 0;
						}
						@media (max-width: 390px){
							justify-content: center;
						}
					}
					.recuperar-senha{
						display: flex;
						align-items: center;
						justify-content: flex-end;
						a{
							text-align: right;
							font-family: Poppins;
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 24px;
							/* identical to box height */
	
	
							/* COLOR FINER */
	
							color: #FE8204;
							@media (max-width: 620px){
								font-size: 14px;
							}
						}
						.v-input__slot{
							border-radius: inherit!important;
							padding: inherit!important;
						}
						@media (max-width: 390px){
							justify-content: center;
						}
					}
					.div-wrapper-btn{
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						align-items: center;
						padding: 12px 45px;
						.btn-entrar{
							border-radius: 100px;
							padding: 18px 20px;
							width: 100%;
							height: 60px;
							span{
								font-family: 'Poppins';
								font-style: normal;
								font-weight: bold;
								font-size: 16px;
								line-height: 24px;
								color: #FFFFFF;
								display: inline-flex;
							}
						}
						@media (max-width: 767px) {
							padding: 12px;	
						}
					}
					.wrapper-novo-usuario{
						padding-top: 20px;
						span{
							font-family: 'Poppins';
							font-style: normal;
							font-weight: 300;
							font-size: 16px;
							line-height: 24px;
							color: #747474;
						}
						.btn-cadastrese{
							background-color: transparent!important;
							box-shadow: inherit!important;
							padding: 0 6px!important;
							span{
								font-family: Poppins;
								font-style: normal;
								font-weight: 500;
								font-size: 16px;
								line-height: 24px;
								color: #747474;
								text-transform: initial;
							}
							&:before{
								content: none!important;
							}
							&:hover{
								background-color: transparent!important;
							}
						}
					}
					@media (max-width: 767px) {
						padding: 12px;	
					}
				}
			}
		}
		.imagem{
			flex: 1;
		}
		@media (max-width: 767px) {
			padding: 50px 0;	
		}
	}
	.modal-CriaUsuario{
		padding: 30px;
		.v-card__title{
			font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 42px;
            color: #FE8204;
            padding: 16px 30px;
			@media (max-width: 767px) {
				padding: 12px;	
			}
			label{
				font-size: 16px;
				color: #404040;
				width: 100%;
			}
		}
		.v-card__text{
			.v-input__slot{
				border-radius: 100px!important;
				padding-left: 15px;
			}
			@media (max-width: 767px) {
				padding: 12px !important;	
			}
		}
		.v-card__actions{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			.wrapper-btns{
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-around;
			}
		}
		@media (max-width: 767px) {
			padding: 0px;	
		}
	}
	.wrapper-logoFinerFoods{
		position: fixed;
		bottom: 5%;
		right: 5%;
		@media (max-width: 767px){
			display: none;
		}
	}
</style>