<template>
	<figure id="logo_">
		<img class="img-responsive" alt="Logo" :src="logo">
	</figure>
</template>

<script>
	export default {
        props: ["logo"],
		name: 'LogoEmpresas'
	}
</script>

<style lang="scss">
	#logo_{
		justify-content: center;
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		margin: auto;
		max-width: 150px;
		background: #fff;
		border-radius: 20px;
		overflow: hidden;
		margin-bottom: 10px;
		margin-top: 10px;
		img{
			width: 100%;
		}
	}
</style>